import { Loader } from "@googlemaps/js-api-loader";

export default class Google {
    constructor(apiKey) {
        if(!apiKey) {
            return;
        }

        this.apiKey = apiKey;

        return this.init();
    }

    init() {
        const loader = new Loader({
            apiKey: this.apiKey,
            version: "weekly",
            language: "nl",
        });

        return loader;
    }
}
