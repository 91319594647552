// import Swiper from 'swiper/swiper-bundle';
import Swiper from '../../node_modules/swiper/swiper-bundle.esm';

(function () {
    const carousels = document.querySelectorAll('.carousel');

    // Bootstrap breakpoints
    const breakpoints = {
        xs: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-xs'),
        sm: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-sm').replace('px',''),
        md: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-md').replace('px',''),
        lg: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-lg').replace('px',''),
        xl: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-xl').replace('px',''),
        xxl: getComputedStyle(document.documentElement).getPropertyValue('--dw-breakpoint-xxl').replace('px',''),
    }

    window.breakpoints = breakpoints;

    // Gets the grid gutter width
    let gridGutterWidth = Number(getComputedStyle(document.documentElement).getPropertyValue('--dw-gutter-x').replace('rem','')) * 16;

    gridGutterWidth = gridGutterWidth ? gridGutterWidth : 40;

    // All swiper effects
    const effects = ['slide', 'fade', 'cube', 'coverflow', 'flip', 'creative', 'cards'];

    // Carousel Buttons
    const carouselButtons = {
        nextEl: '.carousel__button--next',
        prevEl: '.carousel__button--prev',
        disabledClass: '.carousel__button--disabled',
    };

    // Carousel Dots
    // const carouselDots = {
    //     el: '.carousel__pagination',
    //     type: 'bullets',
    // }

    // Default carousel options
    const carouselOptions = {
        direction: 'horizontal',
        effect: effects[0],
        grabCursor: true,
        loop: true,
        spaceBetween: gridGutterWidth / 2,
        createElements: true,
        watchOverflow: true,
        wrapperClass: 'carousel__wrapper',
        navigation: carouselButtons,

        // Disable preloading of all images
        preloadImages: false,
        // Enable lazy loading
        lazy: {
            loadPrevNext: false,
            checkInView: true,
            elementClass: 'carousel__lazy'
        },

        // Slides
        slideActiveClass: 'carousel__slide--is-active',
        slideClass: 'carousel__slide',
        slideDuplicateClass: 'carousel__slide--duplicate',
        slideNextClass: 'carousel__slide--next',
        slidePrevClass: 'carousel__slide--prev',
        slidesPerView: 1,
        centeredSlides: false,

        // Responsive
        breakpoints: {
            [breakpoints.lg]: {
                spaceBetween: gridGutterWidth,
            }
        }
    }

    carousels.forEach(carousel => {
        carouselOptions.breakpoints[breakpoints.lg].slidesPerView = carousel.getAttribute('data-visible-slides');
        carouselOptions.breakpoints.autoplay = {};

        if (carousel.getAttribute('data-autoplay') == 1) {
            carouselOptions.speed = 4000;
            carouselOptions.autoplay = {
                delay: 1,
                waitForTransition: false,
            };
        }

        if(carousel.classList.contains('carousel--cards')) {
            carouselOptions.slidesPerView = 1.2;
            carouselOptions.breakpoints[breakpoints.lg] = {
                spaceBetween: gridGutterWidth,
                slidesPerView: 4,
            };
        }

        if(carousel.classList.contains('carousel--suppliers')) {
            carouselOptions.slidesPerView = 2;
            carouselOptions.spaceBetween = gridGutterWidth;

            carouselOptions.autoplay = {
                delay: 1,
                waitForTransition: false,
            };
            carouselOptions.allowTouchMove = false;
            carouselOptions.grabCursor = false;
            carouselOptions.speed = 3000;
            carouselOptions.centeredSlides = true;

            carouselOptions.breakpoints[breakpoints.lg] = {
                spaceBetween: gridGutterWidth * 0.5,
                slidesPerView: 5,
                centeredSlides: false
            };
        }

        new Swiper(carousel, carouselOptions);
    });
})();
