import Google from '../modules/google';

export default class Maps
{
    constructor(maps, apiKey) {
        if (!maps && !apiKey){
            return;
        }

        this.maps = maps;
        this.apiKey = apiKey;

        this.init();
    }

    init() {
        const loader = new Google(this.apiKey);

        loader.load().then(async () => {
            const { Map } = await google.maps.importLibrary("maps");

            this._loadMaps();
        });
    }

    _loadMaps() {

        const infowindow = new google.maps.InfoWindow();

        this.maps.forEach(map => {
            const locations = JSON.parse(map.getAttribute('data-locations'));
            const lat = Number(map.getAttribute('data-lat'));
            const lng = Number(map.getAttribute('data-lng'));
            const zoom = Number(map.getAttribute('data-zoom'));

            const googleMap = new google.maps.Map(map, {
                zoom: zoom ? zoom : 7,
                center: new google.maps.LatLng(lat ? lat : 52.092876, lng ? lng : 5.104480),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scaleControl: true,
                streetViewControl: false,
                zoomControl: true,
                fullscreenControl: false
            });
    
            var marker, i;
    
            for (i = 0; i < locations.length; i++) {
                const icon = {
                    url: locations[i][3], // url
                    scaledSize: new google.maps.Size(24, 24), // scaled size
                };

                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(locations[i][1], locations[i][2]),
                    icon: icon,
                    map: googleMap
                });
    
                google.maps.event.addListener(marker, 'click', (function(marker, i) {
                    return function() {
                        googleMap.panTo(marker.getPosition());
                        infowindow.setContent(locations[i][0]);
                        infowindow.open(googleMap, marker);
                        googleMap.setZoom(12);
                    }
                })(marker, i));
            }
        });
    }
}
