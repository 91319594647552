export default class Tabs
{
    constructor(tabs) {
        if (!tabs) {
            return;
        }

        this.tabs = tabs;
        this.activeClass = 'is-active';

        this.init();
    }

    init() {
        this.tabs.forEach(tabsComponent => {
            this.component = tabsComponent;

            this._registerEvents();
        });
    }

    _registerEvents() {
        this._pageLoadedEvent();
        this._clickEvents();
    }

    _pageLoadedEvent() {
        document.addEventListener('DOMContentLoaded', () => {
            if(this.component.querySelectorAll('.tabs__item')[0]) {
                this._setActiveTab(this.component.querySelectorAll('.tabs__item')[0]);
            }

            this._clickEvents();
        });
    }

    _clickEvents() {
        const tabItems = this.component.querySelectorAll('.tabs__item');

        if(!tabItems) {
            return;
        }

        tabItems.forEach(tabItem => {
            tabItem.addEventListener('click', (e) => {
                this._removeActiveTab();
                this._setActiveTab(e.target);

                if (window.matchMedia('(max-width: 992px)').matches) {
                    this._scrollToActiveTab(e.target);
                }
            });
        });
    }

    _setActiveTab(tab) {
        if(!tab) {
            return;
        }

        const tabTarget = tab.getAttribute('data-tab-target');
        const tabContent = document.querySelector(`[data-tab-content="${tabTarget}"]`);

        tab.classList.add(this.activeClass);
        tabContent.classList.add(this.activeClass);
    }

    _removeActiveTab() {
        const activeTab = this.component.querySelector(`.tabs__item.${this.activeClass}`);

        if(!activeTab) {
            return;
        }

        const tabTarget = activeTab.getAttribute('data-tab-target');
        const tabContent = document.querySelector(`[data-tab-content="${tabTarget}"]`);

        activeTab.classList.remove(this.activeClass);
        tabContent.classList.remove(this.activeClass);
    }

    _scrollToActiveTab(tab) {
        if (!tab) return;

        const tabTarget = tab.getAttribute('data-tab-target');
        const tabContent = document.querySelector(`[data-tab-content="${tabTarget}"]`);

        tabContent.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }
}
