export default class Accordion {
    constructor(accordion) {
        if (!accordion) {
            return;
        }

        this.accordion = accordion;
        this.accordionBody = 'accordion__body';
        this.accordionContent = 'accordion__content';
        this.activeClass = 'is-active';

        this.init();
    } 

    init() {
        this._registerEvents();
    }

    _registerEvents() {
        this._pageLoadedEvent();
        this._resizeEvent();
        this._clickEvents();
    }

    _pageLoadedEvent() {
        this._calculateActiveHeight();
    }

    _resizeEvent() {
        window.addEventListener('resize', () => {
            this._calculateActiveHeight();
        });
    }

    _clickEvents() {
        this.accordion.addEventListener('click', (e) => {
            this._toggleAccordion(e.target.closest('.accordion'));
        });
    }

    _toggleAccordion(accordion) {
        if(!accordion.classList.contains(this.activeClass)) {
            this._setActiveAccordion(accordion);
        }
        else {
            this._removeActiveAccordion(accordion);
        }
    }

    _setActiveAccordion(accordion) {
        accordion.classList.add(this.activeClass);
    }

    _removeActiveAccordion(accordion) {
        accordion.classList.remove(this.activeClass);
    }

    _calculateActiveHeight(accordion = this.accordion) {
        const componentHeight = accordion.querySelector(`.${this.accordionContent}`).offsetHeight;
        accordion.style.setProperty('--activeHeight', `${componentHeight}`);
    }
}
