export default class downloadFilter{
    constructor(inputs, resultWrapper) {
        if(!inputs && resultWrapper) {
            return;
        }

        this.inputs = inputs;
        this.resultWrapper = resultWrapper;

        this.init();
    }

    init(){
        this._registerEvents();
    }

    _registerEvents() {
        this._inputEvents();
    }

    _inputEvents() {
        this.inputs.forEach(input => {
            input.addEventListener('input', (e) => {
                const filter = e.target.value.toLowerCase();
                let count = 0;

                this.resultWrapper.forEach(wrap => {
                    const resultCols = wrap.querySelectorAll('.downloads--column');

                    resultCols.forEach(col => {
                        if(col.textContent.toLowerCase().includes(filter)) {
                            col.style.display = "block";
                            count++;
                        }
                        else {
                            col.style.display = "none";
                        }
                    });
                });
            });
        });
    }
}
