const container = document.querySelector('.container');

const containerWidth = container.offsetWidth;
document.documentElement.style.setProperty('--container-margin', `${(window.innerWidth - containerWidth) / 2}px`);

window.addEventListener('resize', () => {
    const containerWidth = container.offsetWidth;
    document.documentElement.style.setProperty('--container-margin', `${(window.innerWidth - containerWidth) / 2}px`);
});

class uspCounter {
    constructor(usps) {
        if (!usps) {
            return;
        }

        this.usps = usps;

        this.init();
    }

    init() {
        this.usps.forEach(usps => {
            const uspItems = usps.querySelectorAll('.counter__item');

            uspItems.forEach(item => {
                this.animate(item);
            });
        });
    }

    getNumbers(string) {
        return Number(string.replace(/[^0-9]/g, ""));
    }

    getString(string) {
        return string.replace(/[0-9]*\.[0-9]+/g, "");
    }

    animateCount({ startNum, endNum, duration, easing, stepCallback, completeCallback }) {
        const startTime = performance.now();

        function update() {
            const currentTime = performance.now();
            const elapsedTime = Math.min(currentTime - startTime, duration);

            const progress = elapsedTime / duration;
            const currentVal = startNum + (endNum - startNum) * progress;

            stepCallback(currentVal);

            if (elapsedTime < duration) {
                requestAnimationFrame(update);
            } else {
                completeCallback();
            }
        }

        requestAnimationFrame(update);
    }

    animate(item) {
        const currentEl = item.querySelector('.hero__counter-number');
        // const allLetters = this.getString(currentEl.getAttribute('data-title'));
        const count = this.getNumbers(item.querySelector('.hero__counter-number').getAttribute('data-title'));

        this.animateCount({
            startNum: 0,
            endNum: count,
            duration: 1500,
            easing: 'swing',
            stepCallback: function (countNum) {
                currentEl.textContent = countNum.toFixed(0);
            },
            completeCallback: function () {
                currentEl.textContent = currentEl.getAttribute('data-title');
            }
        });
    }
}

new uspCounter(document.querySelectorAll('.hero__counters'));
