const header = document.querySelector('.main-header');
const menuToggler = header.querySelector('.navbar-toggler');
const subMenuTogglers = document.querySelectorAll('.nav__item--parent');

const headerOpeningClass = 'main-header--opening';
const headerOpenClass = 'main-header--is-open';
const submenuOpenClass = 'nav__item--is-open';

if (menuToggler) {
    menuToggler.addEventListener('click', function (e) {
        if (header.classList.contains(headerOpenClass)) {
            header.classList.add(headerOpeningClass);
            header.classList.remove(headerOpenClass);
            setTimeout(function () {
                header.classList.remove(headerOpeningClass);
            }, 300);
        }
        else {
            header.classList.add(headerOpeningClass);
            setTimeout(function () {
                header.classList.add(headerOpenClass);
                header.classList.remove(headerOpeningClass);
            }, 300);
        }
    });
}

if (subMenuTogglers) {
    subMenuTogglers.forEach(toggler => {
        toggler.addEventListener('click', function (e) {
            this.classList.toggle(submenuOpenClass);
        });
    })
}

// Adjusts the height of mega menu on hovering
const megaMenu = header.querySelector('.nav--megamenu');
if (megaMenu) {
    const megaMenuNavItems = megaMenu.querySelectorAll('.nav-item--parent');

    megaMenuNavItems.forEach(megaMenuNavItem => {
        let heights = [];
        let height;

        megaMenuNavItem.addEventListener('mouseenter', function () {
            if (this.parentNode.classList.contains('nav--megamenu')) {
                heights.push(megaMenuNavItem.querySelector('.nav--submenu').offsetHeight);
            }
            else {
                heights.push(megaMenuNavItem.querySelector('.nav--submenu').offsetHeight + megaMenuNavItem.offsetHeight);
            }

            height = heights.reduce(function (a, b) { return a > b ? a : b; });
            megaMenu.querySelector('.nav--submenu').setAttribute('style', `--height: ${height}px`);
        });
        megaMenuNavItem.addEventListener('mouseleave', function () {
            megaMenu.querySelector('.nav--submenu').setAttribute('style', '');
        });
    });
}
