import Google from '../modules/google';

export default class address {
    constructor(inputs, apiKey) {
        if(!inputs && !apiKey) {
            return;
        }

        this.inputs = inputs;
        this.apiKey = apiKey;

        this.init();
    }

    init(){
        const loader = new Google(this.apiKey);

        loader.load().then(async () => {
            const { Place } = await google.maps.importLibrary("places");

            this._registerEvents();
        });
    }

    _registerEvents() {
        this._addressInputEvent();
    }

    _addressInputEvent() {
        this.inputs.forEach(input => {
            input.addEventListener('keydown', (e) => {
                const autocomplete = new google.maps.places.Autocomplete(e.target, {
                    componentRestrictions: { country: ['nl','be']},
                    fields: ["address_components", "geometry"],
                    types: ["(regions)"],
                });
            });
        });
    }
}
