class GridHelper {
    active() {
        if (!document.querySelector('.container--overlay-grid')) {
            document.querySelector('body').insertAdjacentHTML('beforeend', `<div class="container container--overlay-grid">
                <div class="row">
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                    <div class="col"><div class="col__inner"></div></div>
                </div>
            </div>`);
            localStorage.setItem('gridHelper', true);

            return `Grid helper active`;
        }
    }
    destroy() {
        if (document.querySelector('.container--overlay-grid')) {
            document.querySelector('.container--overlay-grid').remove();
            localStorage.removeItem("gridHelper");

            return `Grid helper destroyed`;
        }
        else {
            return `Grid helper not active, nothing to destroy`;
        }
    }

    toggle() {
        if (!document.querySelector('.container--overlay-grid')) {
            this.active();
        }
        else {
            this.destroy();
        }
    }
}

let gridHelper = new GridHelper;
window.GridHelper = gridHelper;

if (localStorage.getItem("gridHelper")) {
    gridHelper.active();
}

window.addEventListener('keydown', (e) => {
    if(e.ctrlKey && e.keyCode === 76) {
        gridHelper.toggle();
    }
});
