export default class dataListInput
{
    constructor(datalists)
    {
        if(!datalists) {
            return;
        }

        this.datalists = datalists;

        this.init();
    }

    init() {
        this._registerEvents();
    }

    _registerEvents() {
        this._datalistToggleEvents();
        this._searchEvents();
    }

    _datalistToggleEvents() {
        this.datalists.forEach(input => {
            input.addEventListener('click', (e) => {
                e.stopPropagation();

                const datalistId = `#${e.target.getAttribute('datalist_id')}`;

                this.show(datalistId);
            });
        });

        window.addEventListener('click', (e) => {
            this.datalists.forEach(input => {
                const datalistId = `#${input.getAttribute('datalist_id')}`;

                this.hide(datalistId);
            });
        });

        window.addEventListener('keydown', (e) => {
            if (e.key === "Tab") { // escape key maps to keycode `27`
                const datalistId = `#${document.activeElement.getAttribute('datalist_id')}`;

                if(datalistId !== '#null'){
                    this.show(datalistId);
                }
            }
        });

        window.addEventListener('keydown', (e) => {
            if (e.key === "Escape") { // escape key maps to keycode `27`
                const datalistId = `#${document.activeElement.getAttribute('datalist_id')}`;

                if(datalistId !== '#null') {
                    this.hide(datalistId);
                }
            }
        });
    }

    _searchEvents() {
        this.datalists.forEach(input => {
            const datalistId = `#${input.getAttribute('datalist_id')}`;

            const datalistItems = document.querySelectorAll(`${datalistId} .datalist__item:not(.datalist__item--label)`)

            input.addEventListener('input', (e) => {
                let val = input.value;
                val = val.toLowerCase();

                if(val.length < 1) {
                    datalistItems.forEach(item => {
                        this.show(item);
                    });
                }
                else {
                    datalistItems.forEach(item => {
                        let itemText = item.textContent;
                        itemText = itemText.toLowerCase();

                        if(!itemText.includes(val)) {
                            this.hide(item);
                        }
                        else {
                            this.show(item);
                        }
                    });
                }
            });
        });
    }

    show(selector) {
        if(typeof selector === 'string' || selector instanceof String) {
            document.querySelector(selector).style.display = 'block';
        }
        else {
            selector.style.display = 'block';
        }
    }

    hide(selector) {
        if(typeof selector === 'string' || selector instanceof String) {
            document.querySelector(selector).style.display = 'none';
        }
        else {
            selector.style.display = 'none';
        }
    }
}
