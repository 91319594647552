import dataListInput from "./datalist";
import address from "./address";
import downloadFilter from './download-filter';

new dataListInput(
    [...document.querySelectorAll('[datalist_id]')]
);

new address(
    [...document.querySelectorAll('.form-control--address')],
    'AIzaSyAnPfZ2WOOsHRNOn0AbRzqAMIyFxREPhdo'
);

new downloadFilter(document.querySelectorAll('.downloads-filter'), document.querySelectorAll('.downloads-result'));
