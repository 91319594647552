import lozad from 'lozad';
import GLightbox from 'glightbox';
import Tabs from './components/tabs';
import Maps from './components/maps';
import Accordion from './components/accordion';

// Components

GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
});

new Tabs([...document.querySelectorAll('.tabs')]);
new Maps(document.querySelectorAll('[data-component="maps"]'), 'AIzaSyAnPfZ2WOOsHRNOn0AbRzqAMIyFxREPhdo');
[...document.querySelectorAll('[data-accordion]')].forEach(accordion => {
    new Accordion(accordion);
})


// Custom Code

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

const myObserver = new IntersectionObserver(elements => {
    if(elements[0].boundingClientRect.y < 0) {
        document.querySelector('.main-header').classList.remove('main-header--transparent');
    }
    else {
        document.querySelector('.main-header').classList.add('main-header--transparent');
    }
});
  
const pixelToWatch = document.querySelector("#pixel-to-watch");

if(document.querySelector('.main-header').classList.contains('main-header--transparent')) {
    myObserver.observe(pixelToWatch);
}
